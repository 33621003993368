var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-container d-flex content align-items-center" },
    [
      _c("ul", { staticClass: "nav navbar-nav d-xl-none" }, [
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "b-link",
              {
                staticClass: "nav-link",
                on: { click: _vm.toggleVerticalMenuActive }
              },
              [_c("feather-icon", { attrs: { icon: "MenuIcon", size: "21" } })],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "bookmark-wrapper align-items-center flex-grow-1 " },
        [
          _c("h3", { staticClass: " ml-1 mb-0" }, [
            _vm._v(_vm._s(_vm.$route.meta.title))
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-inline-spacing  bg-transparent mt-n1 " },
        [_c("NotificationIcon")],
        1
      ),
      _c("div", { staticClass: "demo-inline-spacing  bg-transparent mt-n1 " }, [
        _c(
          "div",
          { staticClass: "hoverable" },
          [_vm.permissions ? _c("AppDropdown") : _vm._e()],
          1
        )
      ]),
      _c(
        "b-navbar-nav",
        { staticClass: "nav align-items-center ml-auto" },
        [
          _vm.user
            ? _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "dropdown-user",
                  attrs: {
                    right: "",
                    "toggle-class":
                      "d-flex align-items-center dropdown-user-link"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-sm-flex d-none user-nav" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "user-name  mb-0 font-weight-700"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.user.first_name +
                                            " " +
                                            _vm.user.last_name
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("span", { staticClass: "user-status" }, [
                                  _vm._v(_vm._s(_vm.practiceDetails.name) + " ")
                                ])
                              ]
                            ),
                            _c("b-avatar", {
                              staticClass: "badge-minimal",
                              attrs: {
                                src: _vm.getUserImage(_vm.user.id),
                                badge: "",
                                "badge-variant": "success",
                                size: "40",
                                variant: "light-primary"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    207326769
                  )
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "userImg" },
                          [
                            _c("b-avatar", {
                              staticClass: "badge-minimal align-items-center",
                              attrs: {
                                size: "60",
                                variant: "light-primary",
                                badge: "",
                                src: _vm.getUserImage(_vm.user.id, true),
                                "badge-variant": "success"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "userName" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.user.first_name) +
                                " " +
                                _vm._s(_vm.user.last_name)
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "userMail" }, [
                          _vm._v(" " + _vm._s(_vm.user.email) + " ")
                        ]),
                        _c("div", { staticClass: "goToDashboard" }),
                        _vm.checkStatus()
                          ? _c("div", { staticClass: "viewUserAccount" }, [
                              _c("p", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn manageBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/staff-bank/" + _vm.user.id
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Manage your account")]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _c("b-dropdown-divider"),
                        _c("div", { staticClass: "ItemsStylish" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("p", { staticClass: "dashboaerdLink" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-info",
                                    on: {
                                      click: function($event) {
                                        return _vm.profileAccountChange()
                                      }
                                    }
                                  },
                                  [_vm._v("Go to Account Dashboard")]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("b-dropdown-divider"),
                        _c(
                          "div",
                          { staticClass: "logoutButton" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn manageBtn",
                                attrs: { type: "submit", variant: "white" },
                                on: {
                                  click: function($event) {
                                    return _vm.localLogout()
                                  }
                                }
                              },
                              [_vm._v(" Logout of all accounts ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }