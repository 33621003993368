<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media"
    style="list-style-type:none;"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="un_read_notification.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h3 class="notification-title m-1 mr-auto font-weight-bolder dark">
          Notifications
        </h3>
      </div>
    </li>

    <!-- Notifications -->

    <vue-perfect-scrollbar
      v-if="notifications.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      style="height: 357px;border: 1px solid #E5E5E5;border-radius: 4px;"
      tagname="li"
    >
      <ul class="list-group">
        <li>
          <b-media
            v-for="notification in userNotifications"
            :key="notification.subtitle"
          >
            <div class="notificationBar">
              <b-row class="mt-n1 ml-0 mr-0">
                <!-- Left Side -->

                <b-col class="pb-0 pt-1" cols="11" @click="redirectUser(notification)">
                  <div class="row">
                    <div class="d-flex align-items-center  ">
                      <!-- <b-avatar v-if="notification.avatar" :src="notification.avatar" class="ml-1" size="32"/> -->
                      <b-avatar class="ml-1" size="32" variant="light-info">
                        <feather-icon :icon="notification.icon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <p class="mb-0 font-weight-bolder" v-bind:class="notification.is_read===0?text_color1:text_color2">
                          {{ notification.text }}
                        </p>
                        <p
                          class="mb-0 font-weight-bolder" v-bind:class="notification.is_read===0?text_color1:text_color2"
                          v-if="notification.created_at"
                        >
                          ({{
                            momentFormat(notification.created_at, 'HH:mm')
                          }}
                          - {{ momentFormat(notification.updated_at, 'HH:mm') }})
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>

                <!-- Right side Trash button -->

                <b-col class="pt-0  pt-xl-2 pb-xl-0 ml-n1 " cols="1">
                  <b-row>
                    <div
                      class="d-flex justify-content-start  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                    >
                      <b-button
                        v-b-tooltip.hover.top="'Delete'"
                        class="btn-icon  text-primary"
                        size="23"
                        variant="outline-white"
                      >
                        <feather-icon
                          class="cart-item-remove cursor-pointer"
                          icon="TrashIcon"
                          size="18"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-media>
        </li>
      </ul>
    </vue-perfect-scrollbar>

    <!-- No Notifications -->
    <div v-if="!notifications.length" class="text-center">
      <b-card class="d-flex align-items-center bg-light">
        <div class="m-5">
          <div class="mb-2">
            <b-avatar rounded size="100" variant="primary">
              <feather-icon icon="BellIcon" size="600" />
            </b-avatar>
          </div>
          <h5>No Notifications</h5>
        </div>
      </b-card>
    </div>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer m-1" v-if="userNotifications.length>0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        @click="readAllNotifications"
        >Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  VBTooltip,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import notification from '@/apis/modules/notifications';
import practice from '@/apis/modules/practice';
import MomentMixin from '@/mixins/MomentMixin';
import {loginAppUrl } from '@/constants/config';

export default {
  components: {
    BRow,
    BCol,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
    VBTooltip,
  },
  mixins: [MomentMixin],

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      text_color1: 'textColor1',
      text_color2: 'textColor2',
      hiddenNotoificationBar:false,
      un_read_notification:[],
      userNotifications: [],
      beforeLength: 0,
    };
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Diana Jones applied to your shift',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'Today, 12:35',
        icon: '',
        bg: '',
        color: 'danger',
      },
      {
        title: 'Marc Ford applied to your shift',
        avatar: require('@/assets/images/avatars/5-small.png'),
        subtitle: 'Mar 15, 11:00',
        icon: '',
        bg: '',
        color: 'danger',
      },
      {
        title: 'Your monthly review is ready',
        avatar: '',
        subtitle: 'Mar 15, 09:00',
        icon: 'FileIcon',
        bg: 'light-success',
        color: 'danger',
      },
      {
        title: 'New user successfully created',
        avatar: '',
        subtitle: 'Mar 14, 04:00',
        icon: 'UserCheckIcon',
        bg: 'light-info',
        color: 'white',
      },
      {
        title: 'Steve Ross sent you a message',
        avatar: require('@/assets/images/avatars/10-small.png'),
        subtitle: 'Mar 14, 01:05',
        icon: '',
        bg: 'light-danger',
        color: 'white',
      },

      {
        title: 'Jemis Antano sent you a message',
        avatar: require('@/assets/images/avatars/4-small.png'),
        subtitle: 'Mar 13, 09:00',
        icon: '',
        bg: 'light-danger',
        color: 'white',
      },

      {
        title: 'Janita Rayes sent you a message',
        avatar: require('@/assets/images/avatars/7-small.png'),
        subtitle: 'Mar 13, 07:30',
        icon: '',
        bg: 'light-danger',
        color: 'white',
      },
    ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      notifications,
      perfectScrollbarSettings,
    };
  },
  methods: {
   async redirectUser(data){
     try{
       await practice.updateNotifications(data.id);
       data.is_read = 1
       this.un_read_notification.length--
     if(data.type === 'shift_notification'){
       this.$router.push('/my-shifts')
     }else if(data.type === 'vacant_shift_notification'){
       this.$router.push('/scheduler')
     }else if(data.type === 'connection_notification'){
       this.$router.push('/medicalpractice')
     }
     }catch(e){

     }
    
    },
    async getNotifications(chanel) {
      try {
        const notification = await practice.getNotifications();
        let shift_notification =
          notification.data.notification.shift_notification.map((x)=>({
            id : x.id,
            text : x.text,
            created_at : x.created_at,
            end_time : x.end_time,
            start_time: x.start_time,
            is_read: x.is_read,
            type : 'shift_notification'
          }))
          
        let vacant_shift_notification =
          notification.data.notification.vacant_shift_notification.map((x)=>({
            id : x.id,
            text : x.text,
            created_at : x.created_at,
            end_time : x.end_time,
            start_time: x.start_time,
            is_read: x.is_read,
            type : 'vacant_shift_notification'
          }))
        let connection_notification =
          notification.data.notification.connection_notification.map((x)=>({
            id : x.id,
            text : x.text,
            created_at : x.created_at,
            end_time : x.end_time,
            start_time: x.start_time,
            is_read: x.is_read,
            type : 'connection_notification'
          }))
        this.userNotifications = [...shift_notification, ...vacant_shift_notification, ...connection_notification]
        this.un_read_notification = this.userNotifications.filter(x=>x.is_read===0)
        if (chanel) {
          if (this.beforeLength < notification.data.notification.length)
            this.showSuccessMessage('you get new notification');
          this.beforeLength = notification.data.notification.length;
        }
        this.beforeLength = notification.data.notification.length;
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },

    async readAllNotifications(){
     try{
      let notificationsID = []
      let index = 0
      for(let x=0; x< this.userNotifications.length; x++){
        if(this.userNotifications[x].is_read === 0){
          notificationsID[index] = this.userNotifications[x].id
          index++;
        }
      }
      await practice.updateAllNotifications(notificationsID); 
     }catch(e){

     }
   
    }
  },
  mounted() {
    this.getNotifications();
    window.Echo.channel('channel').listen('ShiftEvent', (e) => {
      this.getNotifications(true);
    });
  },
};
</script>

<style>
element.style {
  list-style: none !important;
}

.notificationBar{
  cursor: pointer;
  color: brown
}

.textColor1{
  color: brown;
}

.textColor2{
  color: rgb(0, 0, 0);
}
</style>
