export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon',
    permission: ['practice-admin'],
  },
  {
    title: 'Rota',
    route: 'scheduler',
    icon: 'CalendarIcon',
    permission: ['practice-admin'],
  },
  {
    title: 'My Shifts',
    route: 'my-shifts',
    icon: 'CalendarIcon',
    permission: ['salaried-staff'],
  },
  {
    title: 'Leave Management',
    route: 'admin-leave',
    icon: 'CoffeeIcon',
    permission: ['practice-admin'],
  },
  {
    title: 'My Leaves',
    route: 'leave-management',
    icon: 'CoffeeIcon',
    permission: ['salaried-staff'],
  },
  // 'practice-admin'
  // {
  //   title: 'My Leave',
  //   route: 'my-leaves',
  //   icon: 'CoffeeIcon',
  //   permission: ['salaried-staff'],
  // },
  {
    title: 'My Documents',
    route: 'my-documents',
    icon: 'FileIcon',
    permission: ['salaried-staff'],
  },
  // {
  //   title: 'Leave',
  //   route: 'approve-leaves',
  //   icon: 'CoffeeIcon',
  //   permission: ['practice-admin'],
  // },
  {
    title: 'Staff Bank',
    route: 'staff-bank',
    icon: 'UsersIcon',
    permission: ['practice-admin'],
  },
  {
    title: 'My Timesheet',
    route: 'timesheet',
    icon: 'ClockIcon',
    permission: ['salaried-staff'],
  },
  {
    title: 'Payments',
    route: 'payments',
    icon: 'FileTextIcon',
    permission: ['practice-admin'],
  },
  // {
  //   title: 'Timesheet',
  //   route: 'approve-timesheet',
  //   icon: 'ClockIcon',
  //   permission: ['practice-admin'],
  // },
  {
    title: 'Medical Practice',
    route: 'medicalpractice',
    icon: 'HomeIcon',
    permission: ['practice-admin'],
  },
  // {
  //   title: 'Statistics',
  //   route: 'statistics',
  //   icon: 'PieChartIcon',
  //   permission: ['practice-admin'],
  // },

  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
    permission: ['practice-admin'],
  },
  // {
  //   title: 'Admin Leave',
  //   route: 'admin-leave',
  //   icon: 'CoffeeIcon',
  //   permission: ['practice-admin'],
  // },
  // {
  //   title: 'Leave Management',
  //   route: 'leave-management',
  //   icon: 'CoffeeIcon',
  //   permission: ['salaried-staff'],
  // },
];
