<template>
  <ul>
 <component
        :is="resolveNavItemComponent(item)"
        v-for="item in items"
        v-if="checkPermission(item.permission)"
        :key="item.header || item.title"
        ref="navBar"
        :item="item"

    />
       <!-- v-if="checkPermissionFunction(item.permission ? item.permission:null)" -->


  </ul>
</template>

<script>
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils'
import {provide, ref} from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import {mapGetters,mapActions} from 'vuex'
import store from '../../../../../../../store/index'
import practice from '@/apis/modules/practice'

export default {
  data () {
    return {
      checkFirst: false,
      userPermission: null

    }
  },
  watch: {
    userPermission () {
    }
  },
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup () {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent
    }
  },
  methods: {
    checkPermission (permissionRoute) { 

      if (this.permissions) return JSON. parse(sessionStorage. getItem("currentUserSelectedPermission")).some(i => permissionRoute.includes(i))
      
    },

    switchToSalaried(){
      let currentUserSelectedPermission=[]
      currentUserSelectedPermission.push('salaried-staff')
      sessionStorage.setItem('currentUserSelectedPermission',JSON. stringify(currentUserSelectedPermission))
      window.location = '/my-shifts'
    },

    switchToAdmin(){
     let currentUserSelectedPermission=[]
      currentUserSelectedPermission.push('practice-admin')
      sessionStorage.setItem('currentUserSelectedPermission',JSON. stringify(currentUserSelectedPermission))
      window.location = '/dashboard'
    }
    // async checkPermissionFunction(permissionRoute){
    //
    //
    //   if (permissionRoute === null){
    //     return false
    //   }else {
    //     if (JSON.parse(sessionStorage.getItem('permission')) !== null){
    //       console.log('nav item')
    //
    //
    //       return JSON.parse(sessionStorage.getItem('permission')).some(i => permissionRoute.includes(i));
    //     }{
    //       console.log('seesion not load')
    //     }
    //
    //
    //     // return  JSON.parse(JSON.stringify(this.permissions)).some(i => permissionRoute.includes(i));
    //   }
    //
    //
    //
    //
    //
    //
    //
    //     // return  ['customer'].includes(permission)
    //     // permission.some(el =>el ==='practice-admin')
    //   // }
    //
    // }

  },
  created () {
    // if(this.userPermission ===null){
    //   this.userPermission =
    // }else {
    //
    // }
  },
  computed: {
    ...mapGetters(['permissions','selectedPermission'])


  },

   mounted () {
  //  this.checkPermission()
    this.$root.$on("changeAsSalariedStaff", () => {
     this.switchToSalaried()
    });
    this.$root.$on("changeAsPracticeAdmin", () => {
      this.switchToAdmin()
    });


  }
}
</script>
